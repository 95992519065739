import React from 'react'
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
    MDBTypography,
  } from "mdb-react-ui-kit";

class TestimonialCard extends React.Component {
    render() {
        return (       
            <MDBCol>
                <MDBCard>
                <MDBCardBody className="py-4 mt-2 text-dark">
                    <div className="d-flex justify-content-center mb-4">
                    <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                        className="rounded-circle shadow-1-strong"
                        width="100"
                        height="100"
                    />
                    </div>
                    <h5 className="font-weight-bold">{this.props.name}</h5>
                    <h6 className="font-weight-bold my-3">{this.props.company}</h6>
                    <MDBTypography
                    listUnStyled
                    className="d-flex justify-content-center">
                    <li>
                        <MDBIcon fas icon="star" size="sm" color="primary" />
                    </li>
                    <li>
                        <MDBIcon fas icon="star" size="sm" color="primary" />
                    </li>
                    <li>
                        <MDBIcon fas icon="star" size="sm" color="primary" />
                    </li>
                    <li>
                        <MDBIcon fas icon="star" size="sm" color="primary" />
                    </li>
                    <li>
                        <MDBIcon fas icon="star" size="sm" color="primary" />
                    </li>
                    </MDBTypography>
                    <p className="mb-2">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                        {this.props.review}
                    </p>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>
        )
    }
}

export default TestimonialCard