import React from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import IconCard from './components/IconCard';

export default function Deploy() {
  return (
    <>
    <MDBContainer className='py-5'>
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="10" xl="8" className="text-left">
          <h2 className="text-center fs-1 fw-bold mb-4">Deploy and scale seamlessly.</h2>
          <p className="mb-4 pb-2 mb-md-5 pb-md-0 text-center">
            Looking for fast and reliable web hosting? Look no further! Our internal research shows that websites hosted on our NVMe SSD drives load up to 10x faster, leading to better website rankings on all major search engines. That's why the majority of SEO teams choose SSD-based web hosting plans as their foundation. With our feature-rich web hosting plans, you can enjoy all the benefits of SSD hosting at an affordable price.
          </p>
          <p className="mb-4 pb-2 mb-md-5 pb-md-0 text-center">
            Whether you're running an eCommerce store, personal website, IT project, or business, we offer the best rates for SSD web hosting in the USA, Germany, Netherlands, and Singapore. Experience lightning-fast website loading times and exceptional reliability with our top-notch hosting services.
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow className="d-flex justify-content-center">
        <MDBCol size='md'>
        <IconCard 
            title="Ultra Fast Servers"
            text="For optimal performance, ultra-fast NVMe SSD drives are used on every Shared Web Hosting plan we offer."
            iconSource="\assets\img\icon\cpi1.svg"
            iconHeight="68"
            iconWidth="61"
            iconAlt="Ultra Fast Servers"
        />
        </MDBCol>
        <MDBCol size='md'>
        <IconCard 
            title="Cloudlinux & Litespeed Webserver"
            text="Experience lightning-fast website load times with CloudLinux and LiteSpeed web server in any scenario."
            iconSource="\assets\img\icon\feature-icon1.svg"
            iconHeight="68"
            iconWidth="61"
            iconAlt="Cloudlinux & Litespeed Webserver"
        />
        </MDBCol>
        <MDBCol size='md'>
        <IconCard 
            title="Free DDoS Protection"
            text="Rest assured that your website will remain operational around the clock as all our services are safeguarded against DDoS attacks."
            iconSource="\assets\img\icon\cpi5.svg"
            iconHeight="68"
            iconWidth="61"
            iconAlt="Free DDoS Protection"
        />
        </MDBCol>
        <MDBCol size='md'>
        <IconCard 
            title="WordPress Optimized & Free QUIC CDN"
            text="Achieve optimal performance in any scenario with our WordPress-optimized web hosting plans that come equipped with the free LsCache plugin for advanced caching and QUIC CDN integration available across all hosting plans."
            iconSource="\assets\img\icon\feature-icon4.svg"
            iconHeight="68"
            iconWidth="61"
            iconAlt="WordPress Optimized & Free QUIC CDN"
        />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </>
  );
}