import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import PriceCard from './components/PriceCard';
import SharedHostingPlans from './data/SharedHostingPlans';

function HostingPlans() {
  return (
    <>
        <MDBContainer className='py-5'>
            <MDBRow>
                {SharedHostingPlans.map(plan => (
                    <MDBCol size='md'>
                        <PriceCard 
                            planName={plan.planName}
                            price={plan.priceMonth}
                            priceTerm={plan.priceMonthText}
                            priceYear={plan.priceYear}
                            priceTermYear={plan.priceYearText}
                            websiteCount={plan.websiteCount}
                            storageSpace={plan.storageSpace}
                            subDomainCount={plan.subDomainCount}
                            databaseCount={plan.databaseCount}
                            emailCount={plan.emailCount}
                            backupText={plan.backupText}
                            sslText={plan.sslText}
                            locationText={plan.locationText}
                            icon={plan.icon}
                        />
                    </MDBCol>
                ))}
            </MDBRow>
        </MDBContainer>
    </>
  )
}

export default HostingPlans