import React from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import IconCard from './components/IconCard';

export default function ServerLocations() {
  return (
    <>
    <MDBContainer className='py-5'>
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="10" xl="8" className="text-left">
          <h2 className="text-center fs-1 fw-bold mb-2">Global Server Locations</h2>
          <p className="mb-2 pb-2 mb-md-4 pb-md-0 text-center">
          <strong>The global data center reach and the local presence you need.</strong>
          </p>
          <p className="mb-4 pb-2 mb-md-5 pb-md-0 text-center">
          To ensure your website or web app is stored as close as possible to your main customer base, we operate servers all around the world. All servers are operated in three certified and heavily secured datacenters world-wide. If the load time of your website is important to you, choose a location close to your customer base. Our internal research has shown that fast load times are very important for the SEO ranking of websites. It also heavily impacts the conversion rate of customers.
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow className="d-flex justify-content-center">
      <img src="assets/img/media/map.png" data-rjs="2" alt="map_image"></img>
      </MDBRow>
    </MDBContainer>
    </>
  );
}