import React from "react";

import HeaderHome from "../HeaderHome";
import Testimonials from "../Testimonials";
import HostingPlans from "../HostingPlans";
import Deploy from '../Deploy';
import OneClickInstalls from "../OneClickInstalls";
import ServerLocations from "../ServerLocations";

const Home = () => {
    return (
        <>
            <HeaderHome />
            <Deploy />
            <HostingPlans />
            <Testimonials />
            <OneClickInstalls />
            <ServerLocations />
        </>
    );
}

export default Home;