export default [
    {   
        planName: "SSD Basic",
        priceMonth: "1",
        priceYear: "10",
        priceMonthText: "month",
        priceYearText: "year",
        websiteCount: "1",
        storageSpace: "1",
        subDomainCount: "5",
        databaseCount: "5",
        emailCount: "1",
        backupText: "Daily Off-Site Backup",
        sslText: "Free SSL Certificates",
        locationText: "World-Wide Server Locations",
        icon: "assets/img/icon/price-icon.png",
    },
    {   
        planName: "SSD Professional",
        priceMonth: "2",
        priceYear: "20",
        priceMonthText: "month",
        priceYearText: "year",
        websiteCount: "5",
        storageSpace: "5",
        subDomainCount: "10",
        databaseCount: "10",
        emailCount: "10",
        backupText: "Daily Off-Site Backup",
        sslText: "Free SSL Certificates",
        locationText: "World-Wide Server Locations",
        icon: "assets/img/icon/price-icon2.png",
    },
    {   
        planName: "SSD Business",
        priceMonth: "3",
        priceYear: "30",
        priceMonthText: "month",
        priceYearText: "year",
        websiteCount: "Unlimited",
        storageSpace: "10",
        subDomainCount: "Unlimited",
        databaseCount: "Unlimited",
        emailCount: "Unlimited",
        backupText: "Daily Off-Site Backup",
        sslText: "Free SSL Certificates",
        locationText: "World-Wide Server Locations",
        icon: "assets/img/icon/price-icon3.png",
    },
]