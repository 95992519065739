import React from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import IconCard from './components/IconCard';

export default function OneClickInstalls() {
  return (
    <>
    <MDBContainer className='py-5'>
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="10" xl="8" className="text-left">
          <h2 className="text-center fs-1 fw-bold mb-4">1-Click Installs</h2>
          <p className="mb-4 pb-2 mb-md-5 pb-md-0 text-center">
            Softaculous Premium is an auto installer that allows you to easily install over 380 popular CMS and 1115 PHP classes, including WordPress and Drupal with just one click. It is included in every hosting plan for free.
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow className="d-flex justify-content-center">
      <img src='assets/img/install.png' className='img-fluid shadow-4' alt='1-Click Installs' />
      </MDBRow>
    </MDBContainer>
    </>
  );
}