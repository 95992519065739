import React from 'react'
import {
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
  } from "mdb-react-ui-kit";

class IconCard extends React.Component {   
    render() {
        return (
            <>
                <MDBCard className="bg-white py-1">
                <MDBCardImage src={this.props.iconSource} position='top' alt={this.props.iconAlt} width={this.props.iconWidth} height={this.props.iconHeight} className="mt-3" />
                    <MDBCardBody className='text-left'>
                        <MDBCardTitle className='fs-3'>{this.props.title}</MDBCardTitle>
                        <MDBCardText>
                            {this.props.text}
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </>
        )
    }
}

export default IconCard