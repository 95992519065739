import React from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";

class ComparisonTable extends React.Component{
    render() {
        return (
            <>
                <MDBContainer className="py-5">
                <MDBTable
                    responsive
                    striped
                    className="text-successtable-border border-light"
                >
                    <MDBTableHead className="border-light">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">
                        <strong>{this.props.planName1}</strong>
                        </th>
                        <th scope="col">
                        <strong>{this.props.planName2}</strong>
                        </th>
                        <th scope="col">
                        <strong>{this.props.planName3}</strong>
                        </th>
                    </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                    <tr>
                        <th scope="row" className="fw-bold">Websites</th>
                        <td>Host {this.props.websiteCount1} Website</td>
                        <td>Host {this.props.websiteCount2} Website</td>
                        <td>Host {this.props.websiteCount3} Website</td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">NVMe SSD Storage</th>
                        <td>{this.props.storageSpace1} GB</td>
                        <td>{this.props.storageSpace2} GB</td>
                        <td>{this.props.storageSpace3} GB</td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">Bandwidth</th>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">Sub-Domains</th>
                        <td>{this.props.subDomainCount1}</td>
                        <td>{this.props.subDomainCount2}</td>
                        <td>{this.props.subDomainCount3}</td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">Web Server</th>
                        <td><MDBIcon fas icon="check" /></td>
                        <td><MDBIcon fas icon="check" /></td>
                        <td><MDBIcon fas icon="check" /></td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">MySQL Database</th>
                        <td>{this.props.mysqlDatabases1}</td>
                        <td>{this.props.mysqlDatabases2}</td>
                        <td>{this.props.mysqlDatabases3}</td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">E-Mail Account</th>
                        <td>{this.props.emailCount1}</td>
                        <td>{this.props.emailCount2}</td>
                        <td>{this.props.emailCount3}</td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">Free SSL Certificates</th>
                        <td><MDBIcon fas icon="check" /></td>
                        <td><MDBIcon fas icon="check" /></td>
                        <td><MDBIcon fas icon="check" /></td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">Daily Off-Site Backup</th>
                        <td><MDBIcon fas icon="check" /></td>
                        <td><MDBIcon fas icon="check" /></td>
                        <td><MDBIcon fas icon="check" /></td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">Instant Setup</th>
                        <td><MDBIcon fas icon="check" /></td>
                        <td><MDBIcon fas icon="check" /></td>
                        <td><MDBIcon fas icon="check" /></td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">Support</th>
                        <td><MDBIcon fas icon="check" /></td>
                        <td><MDBIcon fas icon="check" /></td>
                        <td><MDBIcon fas icon="check" /></td>
                    </tr>
                    <tr>
                        <th scope="row" className="fw-bold">Locations</th>
                        <td>
                            USA, East Coast<br/>
                            USA, Central<br/>
                            USA, West Coast<br/>
                            EU, Germany<br/>
                            EU, Netherlands<br/>
                            Asia, Singapore<br/>
                        </td>
                        <td>
                            USA, East Coast<br/>
                            USA, Central<br/>
                            USA, West Coast<br/>
                            EU, Germany<br/>
                            EU, Netherlands<br/>
                            Asia, Singapore<br/>
                        </td>
                        <td>
                            USA, East Coast<br/>
                            USA, Central<br/>
                            USA, West Coast<br/>
                            EU, Germany<br/>
                            EU, Netherlands<br/>
                            Asia, Singapore<br/>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <p className="fw-bold">${this.props.priceMonth1}/mo</p>
                            <p>or</p>
                            <p className="fw-bold">${this.props.priceYear1}/yr</p>
                        </td>
                        <td>
                            <p className="fw-bold">${this.props.priceMonth2}/mo</p>
                            <p>or</p>
                            <p className="fw-bold">${this.props.priceYear2}/yr</p>
                        </td>
                        <td>
                            <p className="fw-bold">${this.props.priceMonth3}/mo</p>
                            <p>or</p>
                            <p className="fw-bold">${this.props.priceYear3}/yr</p>
                        </td>
                    </tr>
                    <tr>
                        <th></th>
                        <th><MDBBtn>Buy Now</MDBBtn></th>
                        <th><MDBBtn>Buy Now</MDBBtn></th>
                        <th><MDBBtn>Buy Now</MDBBtn></th>
                    </tr>
                    </MDBTableBody>
                </MDBTable>
                </MDBContainer>
            </>
        )
    }
}

export default ComparisonTable