import React from 'react'
import {
    MDBBtn,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBListGroup,
    MDBListGroupItem,
    MDBCardFooter,
  } from "mdb-react-ui-kit";

class PriceCard extends React.Component {   
    render() {
        return (
            <>
                <MDBCard className="text-center bg-white py-1">
                    <MDBCardHeader className="bg-white py-3">
                        <img src={this.props.icon} width='111' height='109' alt="$10/yearly" className='ms-4' />
                        <p className="text-uppercase mb-2">
                            <h3>{this.props.planName}</h3>
                        </p>
                        <p className="fs-3 mb-0">${this.props.price}/{this.props.priceTerm}</p>
                        <p className='mb-0'>or</p>
                        <p className="fs-3 mb-0">${this.props.priceYear}/{this.props.priceTermYear}</p>
                    </MDBCardHeader>

                    <MDBCardBody>
                    <MDBListGroup light>
                        <MDBListGroupItem>Host <strong>{this.props.websiteCount}</strong> Website</MDBListGroupItem>
                        <MDBListGroupItem><strong>{this.props.storageSpace} GB</strong> NVMe SSD</MDBListGroupItem>
                        <MDBListGroupItem><strong>Unlimited</strong> Bandwidth</MDBListGroupItem>
                        <MDBListGroupItem><strong>{this.props.subDomainCount}</strong> Sub Domains</MDBListGroupItem>
                        <MDBListGroupItem><strong>{this.props.databaseCount}</strong> MySQL Databases</MDBListGroupItem>
                        <MDBListGroupItem><strong>{this.props.emailCount}</strong> Email Account(s)</MDBListGroupItem>
                        <MDBListGroupItem>{this.props.backupText}</MDBListGroupItem>
                        <MDBListGroupItem>{this.props.sslText}</MDBListGroupItem>
                        <MDBListGroupItem>{this.props.locationText}</MDBListGroupItem>
                    </MDBListGroup>
                    </MDBCardBody>

                    <MDBCardFooter className="bg-white py-3">
                    <MDBBtn color="primary" size="lg">
                        Buy now
                    </MDBBtn>
                    </MDBCardFooter>
                </MDBCard>
            </>
        )
    }
}

export default PriceCard