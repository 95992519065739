import ComparisonTable from "../components/ComparisonTable";
import CallToAction from '../components/CallToAction';
import PriceTable from '../components/PriceTable';

const NoPage = () => {
    return (
        <>
            <h1>404</h1>
            <PriceTable />
            <ComparisonTable 
                    planName1="BASIC"
                    planName2="PROFESSIONAL"
                    planName3="BUSINESS"
                    websiteCount1="1"
                    websiteCount2="2"
                    websiteCount3="3"
                    storageSpace1="1"
                    storageSpace2="5"
                    storageSpace3="10"
                    subDomainCount1="5"
                    subDomainCount2="10"
                    subDomainCount3="Unlimited"
                    mysqlDatabases1="5"
                    mysqlDatabases2="10"
                    mysqlDatabases3="Unlimited"
                    emailCount1="1"
                    emailCount2="10"
                    emailCount3="Unlimited"
                    priceMonth1="1"
                    priceMonth2="2"
                    priceMonth3="3"
                    priceYear1="10"
                    priceYear2="20"
                    priceYear3="30"
                />
            <CallToAction />
        </> 
    );
  };
  
  export default NoPage;
  