import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import TestimonialCard from "./components/TestimonialCard"

export default function Testimonials() {
  return (
    <MDBContainer className='py-5'>
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="10" xl="8" className="text-center">
          <h3 className="fw-bold mb-4">Testimonials</h3>
          <p className="mb-4 pb-2 mb-md-5 pb-md-0">
            We take great pride in providing reliable and efficient web hosting services to our clients.But don't just take our word for it. Read what our satisfied customers have to say about their experience with us. Their feedback and comments are a testament to the quality of our services and the level of customer satisfaction we strive to achieve. We hope their stories inspire you to join our community of happy clients and trust us with your web hosting needs.
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow className="text-center">
          <TestimonialCard name="Test" company="Test" review="Test" />
          <TestimonialCard name="Test" company="Test" review="Test" />
          <TestimonialCard name="Test" company="Test" review="Test" />
      </MDBRow>
    </MDBContainer>
  );
}