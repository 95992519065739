import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import ScrollToTop from './components/ScrollToTop';

export default function Footer() {
  return (
    <>
    <ScrollToTop />
    <MDBFooter className='text-center text-lg-start bg-blue text-light pt-5'>
      <section className='d-flex justify-content-center justify-content-lg-between border-top'>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                TheFastHost
              </h6>
              <p>
                We are TheFastHost and we make SDD Web Hosting Easy & Affordable.
              </p>
              <div>
                <a href='' className='me-4 text-reset'>
                  <MDBIcon fab icon="facebook-f" />
                </a>
                <a href='https://twitter.com/TheFastHost' className='me-4 text-reset'>
                  <MDBIcon fab icon="twitter" />
                </a>
                <a href='' className='me-4 text-reset'>
                  <MDBIcon fab icon="google" />
                </a>
                <a href='' className='me-4 text-reset'>
                  <MDBIcon fab icon="instagram" />
                </a>
                <a href='' className='me-4 text-reset'>
                  <MDBIcon fab icon="youtube" />
                </a>
              </div>
            </MDBCol>
            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <a href='/cheap-ssd-hosting' className='text-reset'>
                  Cheap SSD Hosting
                </a>
              </p>
              <p>
                <a href='/cheap-wordpress-hosting' className='text-reset'>
                  WordPress Hosting
                </a>
              </p>
              <p>
                <a href='/cheap-unlimited-hosting' className='text-reset'>
                  Unlimited Hosting
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='https://my.thefasthost.net/' className='text-reset'>
                  Customer Area
                </a>
              </p>
              <p>
                <a href='https://uptime.thefasthost.net/' className='text-reset'>
                    Network Status
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  About Us
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Help</h6>
              <p>
                <MDBIcon icon="envelope" className="me-2" />
                <a className='text-reset' href='https://my.thefasthost.net/client/plugin/support_manager/client_tickets/departments/'>
                    Contact Us
                </a>
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-2" />
                <a className='text-reset' href=''>
                    FAQ & Knowledge Base
                </a>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2018-2023 TheFastHost all rights reserved
      </div>
    </MDBFooter>
    </>
  );
}