import React, { useState } from 'react';
import Modal from 'react';

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

export default function HeaderHome() {
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);

  return (
    <>
        <MDBContainer className='pb-md-5 py-5 bg-blue text-light' fluid>
            <MDBRow>
                <MDBCol md='3'></MDBCol>
                <MDBCol md='3' >
                    <h1 className=''>Cheap SSD Hosting With Instant Activation</h1>
                    <h2 className='small'>20x Faster Web Hosting with Pure NVMe SSD Storage</h2>
                    
                    <div className='mt-5'>
                      <MDBBtn className='text-nowrap bg-primary' size='s' href='#'>Our Hosting Plans</MDBBtn>
                      <MDBBtn floating size='lg' tag='a' alt="How it works" onClick={toggleShow}>
                        <MDBIcon fas icon='play' />
                      </MDBBtn>
                      <a onClick={toggleShow}>How it works</a>
                      <MDBModal show={basicModal} setShow={setBasicModal} className='text-dark' tabIndex='-1'>
                        <MDBModalDialog size="lg"> 
                          <MDBModalContent>
                            <MDBModalHeader>
                              <MDBModalTitle>What is Web Hosting?</MDBModalTitle>
                              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody className='text-center'>
                              <iframe height='400' width='700' src="//www.youtube.com/embed/HqzUtnzwAsg?controls=0&fs=0&modestbranding=1&start=0" allowfullscreen="" frameborder="0"></iframe>
                            </MDBModalBody>
                          </MDBModalContent>
                        </MDBModalDialog>
                      </MDBModal>
                    </div>
                </MDBCol>
                <MDBCol md='3'>
                  <img width="512px" src='./assets/img/home-banner.png' />
                </MDBCol>
                <MDBCol md='3'>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    </>
  );
}