import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../index.css";

import React from "react";


import TopNavBar from '../TopNavBar';
import Footer from "../Footer";

import { Outlet, Link } from "react-router-dom";

const Layout = () => {
    return (
        <>
            <TopNavBar />
            <Outlet />
            <Footer />
        </>
    );
}

export default Layout;