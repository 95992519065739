import React, { useState } from 'react'
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";

function TopNavBar() {
  const [showBasic, setShowBasic] = useState(false);

  return (
    <>
      <MDBNavbar expand='lg' dark className='bg-blue'>
        <MDBContainer fluid>
          <MDBNavbarBrand href="/">
            <img height='45px' src='.\assets\img\logo_w.png' />
          </MDBNavbarBrand>

          <MDBNavbarToggler
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowBasic(!showBasic)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                    WordPress & Hosting
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link href='/cheap-ssd-hosting'>Premium SSD Hosting</MDBDropdownItem>
                    <MDBDropdownItem link href='/cheap-wordpress-hosting'>WordPress Hosting</MDBDropdownItem>
                    <MDBDropdownItem link href='/cheap-unlimited-hosting'>Unlimited Hosting <span class="badge badge-primary">New</span></MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/reseller-hosting'>Reseller Hosting</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/email-hosting'>E-Mail Hosting <span class="badge badge-primary">New</span></MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <MDBBtn className='text-nowrap bg-primary' size='s' href='https://my.thefasthost.net/order/main/packages/trial/?group_id=2'>Try 7-Day Free</MDBBtn>
              <MDBBtn className='text-nowrap bg-light text-dark' size='s' href='https://my.thefasthost.net/'>Customer Area</MDBBtn>
            </div>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  )
}

export default TopNavBar