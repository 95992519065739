import React from 'react'
import {
    MDBBtn,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBListGroup,
    MDBListGroupItem,
    MDBCardFooter,
  } from "mdb-react-ui-kit";

class PriceTable extends React.Component {   
    render() {
        return (
            <>
                <section class="price-bg-shape style--two pt-120 pb-90">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="section-title text-white text-center price-plan">
                                    <h5>SSD Web Hosting Plan</h5>
                                    <h2>Our Best Pricing</h2>
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-center">
                            <div class="col text-center">
                                <ul class="nav nav-pricing style--two">
                                    <li>
                                        <button class="active" data-bs-toggle="tab" data-bs-target="#monthly">Monthly</button>
                                    </li>
                                    <li>
                                        <button data-bs-toggle="tab" data-bs-target="#yearly">Yearly (2 months off)</button>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="monthly">
                                        <div class="row justify-content-center">
                                            <div class="col-xl-4 col-md-6 col-sm-10">
                                                <div class="single-price-wrap style--two">
                                                    <div class="single-price-bg-shape"></div>
                                                    <div class="single-price">
                                                        <div class="price-head">
                                                            <div class="price-icon">
                                                                <img src="assets/img/icon/price-icon.png" alt="$1/monthly" />
                                                            </div>
                                                            <h3>SSD Basic</h3>
                                                        </div>
                                                        <div class="price-body">
                                                            <ul class="price-feature-list">
                                                                <li>Host 1 Domain</li>
                                                                <li>1GB NVMe SSD</li>
                                                                <li>Unmetered Bandwidth</li>
                                                                <li>5 Sub Domains</li>
                                                                <li>5 MySQL Databases</li>
                                                                <li>1 Email Account</li>
                                                                <li>Daily Off-Site Backup</li>
                                                                <li>Free SSL Certificates</li>
                                                            </ul>
                        
                                                            <div class="d-flex align-items-center">
                                                                <span class="price" data-bg-img="assets/img/icon/single-price-bg2.png">
                                                                    <sup>$</sup> 1.00 <span>/mo</span>
                                                                </span>
                                
                                                                <a href="https://my.thefasthost.net/order/main/packages/ssd-basic/?group_id=12" class="btn btn-style4 btn-border">Buy Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-md-6 col-sm-10">
                                                <div class="single-price-wrap style--two">
                                                    <div class="single-price-bg-shape"></div>
                                                    <div class="single-price">
                                                        <div class="price-head">
                                                            <div class="price-icon">
                                                                <img src="assets/img/icon/price-icon2.png" alt="$2/monthly" />
                                                            </div>
                                                            <h3>SSD Professional</h3>
                                                        </div>
                                                        <div class="price-body">
                                                            <ul class="price-feature-list">
                                                                <li>Host 5 Domains</li>
                                                                <li>5GB NVMe SSD</li>
                                                                <li>Unmetered Bandwidth</li>
                                                                <li>10 Sub Domains</li>
                                                                <li>10 MySQL Databases</li>
                                                                <li>10 Email Account</li>
                                                                <li>Daily Off-Site Backup</li>
                                                                <li>Free SSL Certificates</li>
                                                            </ul>
                                
                                                            <div class="d-flex align-items-center">
                                                                <span class="price" data-bg-img="assets/img/icon/single-price-bg2.png">
                                                                    <sup>$</sup> 2.00 <span>/mo</span>
                                                                </span>
                                
                                                                <a href="https://my.thefasthost.net/order/main/packages/ssd-professional/?group_id=13" class="btn btn-style4 btn-border">Buy Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-md-6 col-sm-10">
                                                <div class="single-price-wrap style--two">
                                                    <div class="single-price-bg-shape"></div>
                                                    <div class="single-price">
                                                        <div class="price-head">
                                                            <div class="price-icon">
                                                                <img src="assets/img/icon/price-icon3.png" alt="$3/monthly" />
                                                            </div>
                                                            <h3>SSD Business</h3>
                                                        </div>
                                                        <div class="price-body">
                                                            <ul class="price-feature-list">
                                                                <li>Host Unlimited Domains</li>
                                                                <li>10GB NVMe SSD</li>
                                                                <li>Unmetered Bandwidth</li>
                                                                <li>Unlimited Sub Domains</li>
                                                                <li>Unlimited MySQL Databases</li>
                                                                <li>Unlimited Email Account</li>
                                                                <li>Daily Off-Site Backup</li>
                                                                <li>Free SSL Certificates</li>
                                                            </ul>
                                
                                                            <div class="d-flex align-items-center">
                                                                <span class="price" data-bg-img="assets/img/icon/single-price-bg2.png">
                                                                    <sup>$</sup> 3.00 <span>/mo</span>
                                                                </span>
                                
                                                                <a href="https://my.thefasthost.net/order/main/packages/ssd-business/?group_id=14" class="btn btn-style4 btn-border">Buy Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="yearly">
                                        <div class="row justify-content-center">
                                            <div class="col-xl-4 col-md-6 col-sm-10">
                                                <div class="single-price-wrap style--two">
                                                    <div class="single-price-bg-shape"></div>
                                                    <div class="single-price">
                                                        <div class="price-head">
                                                            <div class="price-icon">
                                                                <img src="assets/img/icon/price-icon.png" alt="$10/yearly" />
                                                            </div>
                                                            <h3>SSD Basic</h3>
                                                        </div>
                                                        <div class="price-body">
                                                            <ul class="price-feature-list">
                                                                <li>Host 1 Domain</li>
                                                                <li>1GB NVMe SSD</li>
                                                                <li>Unmetered Bandwidth</li>
                                                                <li>5 Sub Domains</li>
                                                                <li>5 MySQL Databases</li>
                                                                <li>1 Email Account</li>
                                                                <li>Daily Off-Site Backup</li>
                                                                <li>Free SSL Certificates</li>
                                                            </ul>
                                
                                                            <div class="d-flex align-items-center">
                                                                <span class="price" data-bg-img="assets/img/icon/single-price-bg2.png">
                                                                    <sup>$</sup> 10.00 <span>/yr</span>
                                                                </span>
                                
                                                                <a href="https://my.thefasthost.net/order/main/packages/ssd-basic/?group_id=12" class="btn btn-style4 btn-border">Buy Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-md-6 col-sm-10">
                                                <div class="single-price-wrap style--two">
                                                    <div class="single-price-bg-shape"></div>
                                                    <div class="single-price">
                                                        <div class="price-head">
                                                            <div class="price-icon">
                                                                <img src="assets/img/icon/price-icon2.png" alt="$20/yearly" />
                                                            </div>
                                                            <h3>SSD Professional</h3>
                                                        </div>
                                                        <div class="price-body">
                                                            <ul class="price-feature-list">
                                                                <li>Host 5 Domains</li>
                                                                <li>5GB NVMe SSD</li>
                                                                <li>Unlimited Bandwidth</li>
                                                                <li>10 Sub Domains</li>
                                                                <li>10 MySQL Databases</li>
                                                                <li>10 Email Account</li>
                                                                <li>Daily Off-Site Backup</li>
                                                                <li>Free SSL Certificates</li>
                                                            </ul>
                                
                                                            <div class="d-flex align-items-center">
                                                                <span class="price" data-bg-img="assets/img/icon/single-price-bg2.png">
                                                                    <sup>$</sup> 20.00 <span>/yr</span>
                                                                </span>
                                
                                                                <a href="https://my.thefasthost.net/order/main/packages/ssd-professional/?group_id=13" class="btn btn-style4 btn-border">Buy Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-md-6 col-sm-10">
                                                <div class="single-price-wrap style--two">
                                                    <div class="single-price-bg-shape"></div>
                                                    <div class="single-price">
                                                        <div class="price-head">
                                                            <div class="price-icon">
                                                                <img src="assets/img/icon/price-icon3.png" alt="$30/yearly" />
                                                            </div>
                                                            <h3>SSD Business</h3>
                                                        </div>
                                                        <div class="price-body">
                                                            <ul class="price-feature-list">
                                                                <li>Host Unlimited Domains</li>
                                                                <li>10GB NVMe SSD</li>
                                                                <li>Unmetered Bandwidth</li>
                                                                <li>Unlimited Sub Domains</li>
                                                                <li>Unlimited MySQL Databases</li>
                                                                <li>Unlimited Email Account</li>
                                                                <li>Daily Off-Site Backup</li>
                                                                <li>Free SSL Certificates</li>
                                                            </ul>
                                
                                                            <div class="d-flex align-items-center">
                                                                <span class="price" data-bg-img="assets/img/icon/single-price-bg2.png">
                                                                    <sup>$</sup> 30.00 <span>/yr</span>
                                                                </span>
                                
                                                                <a href="https://my.thefasthost.net/order/main/packages/ssd-business/?group_id=14" class="btn btn-style4 btn-border">Buy Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default PriceTable